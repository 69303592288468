import React, { useState } from 'react';
import './App.css';
import { Code2, ArrowRight } from 'lucide-react';
import { toDate, format } from 'date-fns-tz';

function App() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [subscribed, setSubscribed] = useState(false);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const now = new Date();
      const amsterdamTime = toDate(now, { timeZone: 'Europe/Amsterdam' });
      const formattedDate = format(amsterdamTime, "yyyy-MM-dd HH:mm:ss 'CET'", { timeZone: 'Europe/Amsterdam' });

      const response = await fetch('https://automation.honing.me/api/v1/webhooks/Dt9XE9x5AyO8BmYX1UTKy', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          name: name,
          email: email,
          date: formattedDate
        }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      setSubscribed(true);
      setEmail('');
      setError('');
    } catch (err) {
      console.error('Error:', err);
      setError('An error occurred. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <div className="flex flex-col min-h-screen bg-gradient-to-br from-[#2B2D42] to-[#1A1B2E] text-white">
      <header className="p-6 border-b border-white/10">
        <div className="container mx-auto">
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-2">
              <Code2 className="h-8 w-8 text-[#007BFF]" />
              <span className="text-2xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-[#007BFF] to-[#00BFFF]">AI Code Guide</span>
            </div>
            <div className="hidden md:block text-sm font-semibold text-gray-300">
              Unlock AI Potential in Every Line of Code
            </div>
          </div>
        </div>
      </header>

      <main className="flex-grow">
        <section className="py-20 px-4">
          <div className="container mx-auto flex flex-col lg:flex-row items-center justify-between gap-12 lg:gap-24">
            <div className="lg:w-5/12 max-w-xl">
              <h1 className="text-4xl lg:text-5xl font-bold mb-6 leading-tight">
                Unlock the Power of{' '}
                <span className="bg-clip-text text-transparent bg-gradient-to-r from-[#007BFF] to-[#00BFFF]">AI</span> in{' '}
                <span className="bg-clip-text text-transparent bg-gradient-to-r from-[#007BFF] to-[#00BFFF]">Your</span> Projects
              </h1>
              <p className="text-xl mb-8 text-gray-300">
                Learn to create full coding projects with the help of AI tools and expert guidance.
              </p>
              {!subscribed ? (
                <form onSubmit={handleSubmit} className="flex flex-col sm:flex-row gap-4 mb-8">
                  <div className="flex-1 flex flex-col sm:flex-row gap-4">
                    <input
                      type="text"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      placeholder="Enter your name"
                      required
                      className="w-full sm:w-1/2 bg-white/10 border-white/20 text-white placeholder-gray-400 focus:border-[#007BFF] focus:ring-[#007BFF] rounded-md px-4 py-2"
                    />
                    <input
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="Enter your email"
                      required
                      className="w-full sm:w-1/2 bg-white/10 border-white/20 text-white placeholder-gray-400 focus:border-[#007BFF] focus:ring-[#007BFF] rounded-md px-4 py-2"
                    />
                  </div>
                  <button
                    type="submit"
                    className="bg-[#007BFF] hover:bg-[#0056b3] text-white font-semibold py-2 px-4 rounded-md transition-colors duration-300 flex items-center justify-center whitespace-nowrap"
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <div className="loading-spinner"></div>
                    ) : (
                      <>
                        Join Waitlist
                        <ArrowRight className="ml-2 h-5 w-5" />
                      </>
                    )}
                  </button>
                </form>
              ) : (
                <div className="text-xl font-semibold mb-8">
                  <p className="text-[#34d399] mb-2">
                    Thanks for subscribing{name ? `, ${name}` : ''}!
                  </p>
                  <p className="text-[#34d399] text-base">
                    The confirmation email is on its way, make sure to check your spam!
                  </p>
                </div>
              )}
              {error && <p className="text-red-500 mb-4">{error}</p>}
              <p className="text-sm text-gray-400">Join a growing community of poeple like you!</p>
            </div>
            <div className="lg:w-6/12 mt-12 lg:mt-0 flex justify-center items-center">
              <img 
                src="/cursor-ai-herosection.png" 
                alt="AI Code Guide Hero" 
                className="rounded-lg shadow-2xl max-w-full h-auto"
              />
            </div>
          </div>
        </section>

        {/* Value Proposition Section */}
        <section className="py-20 px-4 bg-[#1A1B2E] overflow-hidden">
          <div className="container mx-auto">
            <h2 className="text-4xl font-bold mb-12 text-center text-white">Why Choose AI Code Guide?</h2>
            <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8">
              {[
                {
                  title: "AI-Powered Tools & Updates",
                  description: "Stay ahead with the latest AI tools and updates, helping you enhance your projects efficiently.",
                  icon: (
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12 text-[#007BFF] mb-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                    </svg>
                  )
                },
                {
                  title: "AI-Driven Tutorials",
                  description: "Learn how to create full projects using AI, with step-by-step tutorials using tools like Cursor Composer or Replit Agent.",
                  icon: (
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12 text-[#007BFF] mb-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z" />
                    </svg>
                  )
                },
                {
                  title: "Customizable Project Templates",
                  description: "Access almost fully finished project templates that you can easily customize and deploy, with guides included.",
                  icon: (
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12 text-[#007BFF] mb-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 9l3 3-3 3m5 0h3M5 20h14a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                    </svg>
                  )
                },
                {
                  title: "Exclusive AI Prompts & Templates",
                  description: "Unlock curated AI prompts and project templates to build smarter and faster.",
                  icon: (
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12 text-[#007BFF] mb-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                    </svg>
                  )
                }
              ].map((item, index) => (
                <div 
                  key={index} 
                  className={`bg-[#2B2D42] p-8 rounded-lg shadow-lg border border-[#007BFF]/20 hover:border-[#007BFF] transition-all duration-300 transform hover:scale-105 hover:rotate-1 hover:shadow-xl ${index % 2 === 0 ? 'lg:-mt-4' : ''}`}
                >
                  <div className="absolute top-0 right-0 bg-[#007BFF] text-white text-xs font-bold px-2 py-1 rounded-bl-lg">
                    Limited Spots!
                  </div>
                  {item.icon}
                  <h3 className="text-2xl font-bold mb-3 text-white">{item.title}</h3>
                  <p className="text-gray-300 mb-6">{item.description}</p>
                  <button 
                    className="bg-[#007BFF] hover:bg-[#0056b3] text-white font-semibold py-2 px-4 rounded-md transition-colors duration-300 w-full"
                    onClick={scrollToTop}
                  >
                    Join Waitlist
                  </button>
                </div>
              ))}
            </div>
            <div className="text-center mt-12">
              <p className="text-xl text-white mb-4">Don't miss out on early access!</p>
              <div className="inline-block bg-white/10 rounded-full px-4 py-2 text-white font-semibold">
                <span className="animate-pulse inline-block mr-2">🕒</span>
                Limited spots available
              </div>
            </div>
          </div>
        </section>

        {/* Exclusive Perks Section */}
        <section className="py-20 px-4 bg-gradient-to-br from-[#1A1B2E] to-[#2B2D42]">
          <div className="container mx-auto">
            <h2 className="text-4xl font-bold mb-12 text-center text-white">Exclusive Perks for Early Subscribers</h2>
            <div className="grid md:grid-cols-3 gap-8 mb-12">
              {[
                {
                  title: "25% Off at Launch",
                  description: "Be among the first to join and enjoy a significant discount when we go live.",
                  icon: "💰"
                },
                {
                  title: "Early Access to AI Tools",
                  description: "Get first dibs on our cutting-edge AI coding tools before anyone else.",
                  icon: "🚀"
                },
                {
                  title: "Shape the final product",
                  description: "As an early subscriber, you’ll have a chance to provide direct feedback to shape future tools and features.",
                  icon: "💬"
                }
              ].map((perk, index) => (
                <div 
                  key={index}
                  className="bg-white/10 p-8 rounded-lg shadow-lg border border-[#007BFF]/20 hover:border-[#007BFF] transition-all duration-300 transform hover:scale-105 hover:rotate-1 hover:shadow-xl"
                >
                  <div className="text-4xl mb-4">{perk.icon}</div>
                  <h3 className="text-2xl font-bold mb-3 text-white">{perk.title}</h3>
                  <p className="text-gray-300 mb-6">{perk.description}</p>
                  <div className="absolute top-0 right-0 bg-[#007BFF] text-white text-xs font-bold px-2 py-1 rounded-bl-lg">
                    Waitlist Exclusive!
                  </div>
                </div>
              ))}
            </div>
            <div className="text-center mb-8">
              <p className="text-lg md:text-xl text-white px-4 md:px-0">
                Join our waitlist today to secure 25% off at launch, early access, and exclusive AI coding resources!
              </p>
            </div>
            <div className="text-center">
              <button 
                onClick={scrollToTop}
                className="bg-gradient-to-r from-[#007BFF] to-[#00BFFF] hover:from-[#0056b3] hover:to-[#0098cc] text-white font-bold py-3 px-6 md:py-4 md:px-8 rounded-full transition-all duration-300 transform hover:scale-105 text-lg md:text-xl shadow-lg hover:shadow-xl max-w-[90%] md:max-w-none"
              >
                Join the Waitlist—Lock in Your Discount!
              </button>
            </div>
          </div>
        </section>
      </main>

      <footer className="bg-black/30 py-8 border-t border-white/10">
        <div className="container mx-auto px-4">
          <div className="flex flex-col items-center justify-center space-y-4">
            <div className="flex items-center space-x-2">
              <Code2 className="h-6 w-6 text-[#007BFF]" />
              <span className="text-xl font-bold">AI Code Guide</span>
            </div>
            <div className="text-sm text-gray-400 text-center">
              © 2024 AI Code Guide. All rights reserved.
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default App;